import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Grid from '../components/tiles/grid'
import Pagination from '../components/Pagination'
import MediaQuery from 'react-responsive'

const Author = props => {
  const { data, pageContext } = props
  const { name, slug } = data.wordpressWpUsers
  const { edges: posts, totalCount } = data.allWordpressPost
  const { title: siteTitle } = data.site.siteMetadata
  const title = `${totalCount} post${totalCount === 1 ? '' : 's'} by ${name}`

  const authors_with_palette = ["bill", "cat", "dillon"];
  const palette = (authors_with_palette.includes(slug)) ? `author_${slug}` : "grey";
  return (
    <Layout>
      <div>
        <Helmet title={`${name} | ${siteTitle}`} />

        <MediaQuery maxWidth={639}>
          <Grid
            hasTitle={true}
            palette={palette}
            ratio={.33}
            size={22}
          >
            <b>Author</b>
            <h1>{name}</h1>
          </Grid>
        </MediaQuery>

        <MediaQuery minWidth={640} maxWidth={959}>
          <Grid
            hasTitle={true}
            palette={palette}
            ratio={.33}
            size={176}
          >
            <b>Author</b>
            <h1>{name}</h1>
          </Grid>
        </MediaQuery>

        <MediaQuery minWidth={960} maxWidth={1439}>
          <Grid
            hasTitle={true}
            palette={palette}
            ratio={.33}
            size={217}
          >
            <b>Author</b>
            <h1>{name}</h1>
          </Grid>
        </MediaQuery>
        <MediaQuery minWidth={1440}>
          <Grid
            hasTitle={true}
            palette={palette}
            ratio={.33}
            size={484}
          >

            <b>Author</b>
            <h1>{name}</h1>
          </Grid>
        </MediaQuery>

        <PostList posts={posts}  />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </div>
    </Layout>
  )
}

export default Author

export const pageQuery = graphql`
  query AuthorPage($id: String!, $limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    wordpressWpUsers(id: { eq: $id }) {
      name
      slug
    }
    allWordpressPost(
      filter: { author: {id: {eq: $id } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
